<div class="dialog-container" data-testid="user_dialog">
  <h2 class="title" mat-dialog-title>
    {{ "user_page.change_pwd" | translate }}
  </h2>
  <form
    [formGroup]="form"
    (ngSubmit)="submitHandler(form)"
    data-testid="user_form"
  >
    <div class="body" mat-dialog-content>
      <div class="input-row">
        @if (!isAdmin) {
        <mat-form-field appearance="outline" class="custom-input full-width">
          <mat-label>{{ "user_page.current_password" | translate }}</mat-label>
          <input
            [type]="showOldPwd ? 'text' : 'password'"
            matInput
            required
            formControlName="currentPassword"
            data-testid="password_input"
          />
          <mat-icon
            matIconSuffix
            class="pwd-icon"
            (click)="toggleShowHide($event, true)"
            >{{ showOldPwd ? "visibility_off" : "visibility" }}</mat-icon
          >
          @if (form.get('currentPassword').touched &&
          form.get('currentPassword').hasError('required')) {
          <mat-error>{{
            "user_page.errors.current_password_required" | translate
          }}</mat-error>
          }
        </mat-form-field>
        }
        <mat-form-field appearance="outline" class="custom-input full-width">
          <mat-label>{{ "user_page.new_password" | translate }}</mat-label>
          <input
            [type]="showNewPwd ? 'text' : 'password'"
            matInput
            required
            formControlName="newPassword"
            data-testid="new_password_input"
          />
          <mat-icon
            matIconSuffix
            class="pwd-icon"
            (click)="toggleShowHide($event, false)"
            >{{ showNewPwd ? "visibility_off" : "visibility" }}</mat-icon
          >
          @if (form.get('newPassword').touched &&
          form.get('newPassword').hasError('required')) {
          <mat-error>{{
            "user_page.errors.new_password_required" | translate
          }}</mat-error>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="footer" mat-dialog-actions>
      <button
        mat-stroked-button
        class="dialog-btn"
        (click)="close()"
        type="button"
        data-testid="cancel_btn"
      >
        {{ "actions.cancel" | translate }}
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="dialog-btn"
        type="submit"
        data-testid="submit_btn"
      >
        {{ "actions.update" | translate }}
      </button>
    </div>
  </form>
</div>
