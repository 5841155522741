import { Injectable } from '@angular/core';
import { BaseRequestService } from './base-request.service';
import { BaseQuery } from '../models/query/base-query';
import { lastValueFrom, map } from 'rxjs';
import { BaseApiResponse } from '../models/bases/base-api-response';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _baseRequestService: BaseRequestService) {}

  getUsers(query: BaseQuery) {
    return lastValueFrom(
      this._baseRequestService
        .getJSON<BaseApiResponse>('users', {
          data: query,
          is_loading: true,
        })
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }

  getUserById(id: string) {
    return lastValueFrom(
      this._baseRequestService
        .getJSON<BaseApiResponse>(`users/${id}`, {
          is_loading: true,
        })
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }

  createUser(data: User) {
    return lastValueFrom(
      this._baseRequestService
        .postFile<BaseApiResponse>('users', {
          data: data,
          is_loading: true,
        })
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }

  updateUser(id: string, data: Partial<User>) {
    return lastValueFrom(
      this._baseRequestService
        .patchFile<BaseApiResponse>(`users/${id}`, {
          data: data,
          is_loading: true,
        })
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }

  updateUserPassword(
    id: string,
    data: { old_password: string; new_password: string }
  ) {
    return lastValueFrom(
      this._baseRequestService
        .patchJSON<BaseApiResponse>(`users/${id}/change-pwd`, {
          data: data,
          is_loading: true,
        })
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }

  deleteUser(id: string) {
    return lastValueFrom(
      this._baseRequestService
        .deleteJSON<BaseApiResponse>(`users/${id}`, {
          is_loading: true,
        })
        .pipe(
          map((res) => {
            return res;
          })
        )
    );
  }
}
