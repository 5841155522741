import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDialogData } from '../../../../models/bases/base-dialog-data';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MessageSnackbarService } from '../../../../services/message-snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../services/user.service';
import { AuthService } from '../../../../services/auth.service';
import { UserRoleEnum } from '../../../../models/enums/user-role-enum';

@Component({
  selector: 'app-user-change-pwd-form',
  templateUrl: './user-change-pwd-form.component.html',
  styleUrl: './user-change-pwd-form.component.scss',
})
export class UserChangePwdFormComponent implements OnInit {
  form: FormGroup;
  showOldPwd: boolean = false;
  showNewPwd: boolean = false;
  isAdmin: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: BaseDialogData,
    private _dialogRef: MatDialogRef<UserChangePwdFormComponent>,
    private _formBuilder: FormBuilder,
    private _messageSnackBarService: MessageSnackbarService,
    private _translateService: TranslateService,
    private _userService: UserService,
    private _authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this._authService.userRole === UserRoleEnum.Admin;
    if (this.isAdmin) {
      this.form = this._formBuilder.group({
        newPassword: new FormControl('', [Validators.required]),
      });
    } else {
      this.form = this._formBuilder.group({
        currentPassword: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', [Validators.required]),
      });
    }
  }

  toggleShowHide(event: Event, forOld: boolean): void {
    event.stopPropagation();
    if (forOld) {
      this.showOldPwd = !this.showOldPwd;
    } else {
      this.showNewPwd = !this.showNewPwd;
    }
  }

  submitHandler = async (form: FormGroup) => {
    try {
      if (form.valid) {
        let jsonData;
        if (this.isAdmin) {
          jsonData = {
            password: form.value.newPassword,
          };
        } else {
          jsonData = {
            old_password: form.value.currentPassword,
            new_password: form.value.newPassword,
          };
        }

        let res;

        if (this.isAdmin) {
          res = await this._userService.updateUser(
            this.dialogData.itemId,
            jsonData
          );
        } else {
          res = await this._userService.updateUserPassword(
            this.dialogData.itemId,
            jsonData
          );
        }

        if (res.code === 1) {
          this._dialogRef.close({ succeeded: true });
        } else {
          this._messageSnackBarService.showError(res.message);
        }
      } else {
        this._messageSnackBarService.showError(
          this._translateService.instant('instructions.enter_required_info')
        );
      }
    } catch (error) {
      this._messageSnackBarService.showError(error);
    }
  };

  close() {
    this._dialogRef.close();
  }
}
